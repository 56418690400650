<template>
    <div>
        <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x" style="box-shadow: none;">
        <!-- Brand demo (see demo.css) -->
        <b-navbar-brand to="/" class="app-brand demo d-lg-none py-0 mr-4">
        <span class="app-brand-logo">
            <b-avatar :size="35" style="background-color: #97dd28;" src="/isotipo.png" ></b-avatar>
        </span>
        <span class="app-brand-text demo font-weight-normal ml-2">{{$auth.user().office.name.substring(0,15)}}</span>
        </b-navbar-brand>

        <!-- Sidenav toggle (see demo.css) -->
        <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
        <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
            <i class="ion ion-md-menu text-large align-middle" />
        </a>
        </b-navbar-nav>

        <!-- Navbar toggle -->
        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">

            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2">

            <!-- Search -->
            <!-- <b-navbar-nav class="align-items-lg-center">
                <autocomplete
                    :search="search"
                    placeholder="Buscar pacientes"
                    aria-label="Buscar pacientes"
                    :get-result-value="getResultValue"
                    @submit="handleSubmit"
                >
                    <template #result="{ result, props }">
                    <li
                        v-bind="props"
                        class="autocomplete-result wiki-result"
                    >
                        <div class="wiki-title">
                        {{ result.name }}
                        </div>
                        <div class="wiki-snippet"> {{ result.document_type }} {{ result.document_number }} </div>
                    </li>
                    </template>
                </autocomplete>
            </b-navbar-nav> -->
            <b-navbar-nav class="align-items-lg-center ml-auto">
                <b-nav-item  @click="displaySearchConsole()">
                    <i class="ion ion-ios-search navbar-icon align-middle"></i>
                    <span class="d-lg-none align-middle">&nbsp; Buscar paciente</span>
                </b-nav-item>
                    <!-- Divider -->
                    <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

                    <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
                        <template slot="button-content">
                            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                                <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ $auth.user().first_name }} {{ $auth.user().last_name }}</span>
                                <img :src="`${image_path}/profile/${($auth.user().avatar === null?'default.jpg':$auth.user().avatar)}`" alt class="d-block ui-w-30 rounded-circle">
                            </span>
                        </template>
                        <router-link tag="b-dd-item" :to="{name:'account-settings', params: { id: $auth.user().id } }"><i class="ion ion-ios-person text-lightest"></i> &nbsp; Perfil</router-link>
                        <b-dd-item href="https://docs.google.com/document/d/1CuC4R3lG25F0t0shfQDDXdgBiJh47IuuzLysPRHZt5Q/edit?usp=sharing" target="_blank"><i class="ion ion-md-help-circle-outline text-lightest"></i> &nbsp; Ayuda</b-dd-item>
                        <b-dd-divider />
                        <router-link tag="b-dd-item" :to="{name:'logout'}"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Cerrar Sesión</router-link>
                    </b-nav-item-dropdown>
                </b-navbar-nav>

            </b-collapse>

        </b-navbar>
        <b-modal @shown="focusMyElement" size="xl" title="Liquidaciones" hide-footer hide-header center v-model="showSearchConsole" >
            <div class="row">
                <div class="col-md-12 px-4">
                    <div>
                        <b-btn class="btn icon-btn btn-xs btn-outline-default float-right" @click="showSearchConsole = false">x</b-btn>
                        <input ref="focusThis" autofocus type="text" class="form-control" placeholder="Buscar Paciente" name="" v-model="q" @keyup="doSearch()">
                    </div>
                    <div v-if="results.length === 0" class="mt-4">Introduzca un término de búsqueda</div>
                    <br>
                    <a href="#" @click="handleSubmit(item)" v-for="item in results" :key="item.id" class="mb-1 d-block text-body">
                        <span class="text-largea font-weight-bold">
                            {{item.name}}
                        </span>
                            <span class="text-muted  font-weight-light"> <br>
                                <span class="text-muted" v-if="item.no_document"> Sin documento</span>
                                <span class="text-muted" v-else> Doc. {{item.document_number}}</span>
                            </span>
                        <hr class="mt-1 mb-2">
                    </a>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
    name: 'app-layout-navbar',
    components: {
        // Autocomplete
    },
    props: {
        sidenavToggle: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            q: '',
            results: [],
            showSearchConsole: false,
            app_title: process.env.VUE_APP_TITLE,
            value: '',
            src: 'http://appointments-api/api/patients',
            limit: 5,
            minChars: 3
        }
    },

    methods: {
        focusMyElement () {
            this.$refs.focusThis.focus()
        },
        displaySearchConsole () {
            this.showSearchConsole = true
            // this.$refs.searchConsoleQ.focus()
            console.log(this.$refs.searchConsoleQ)
        },
        doSearch () {
            if (this.q.length < 3) {
                this.results = []
                return false
            }

            this.axios({
                method: 'get',
                url: '/patients',
                // cancelToken: this.request.token,
                // params: { limit: 5, for_user_id: (this.value) ? this.value : null }
                params: { limit: 5, text: this.q }
            })
                .then((response) => {
                    this.results = response.data.data
                    this.isLoading = false
                }).catch((error) => {
                    this.isLoading = false
                    console.log(error)
                })
        },
        search (input) {
            // const url = `${wikiUrl}/w/api.php?${
            //     params
            // }&srsearch=${encodeURI(input)}`

            return new Promise(resolve => {
                if (input.length < 3) {
                    return resolve([])
                }

                this.axios({
                    method: 'get',
                    url: '/patients',
                    // cancelToken: this.request.token,
                    // params: { limit: 5, for_user_id: (this.value) ? this.value : null }
                    params: { limit: 5, text: input }
                })
                    .then((response) => {
                        // this.staffOptions = response.data.data
                        resolve(response.data.data)

                        // if (this.first_time && this.for_user_id) {
                        //     this.staffValue = this.staffOptions[0]
                        //     this.first_time = false
                        //     this.onStaffSelect()
                        // }
                        this.isLoading = false
                    }).catch((error) => {
                        this.isLoading = false
                        console.log(error)
                    })
                // fetch('http://appointments-api/api/patients?text=' + input)
                //     .then(response => response.json())
                //     .then(data => {
                //         resolve(data.query.search)
                //     })
            })
        },
        // Wikipedia returns a format like this:
        //
        // {
        //   pageid: 12345,
        //   title: 'Article title',
        //   ...
        // }
        //
        // We want to display the title
        getResultValue (result) {
            return result.name
        },

        // Open the selected article in
        // a new window
        handleSubmit (result) {
            this.$router.push({ name: 'patients-view', params: { id: result.id } })
            this.showSearchConsole = false
        },
        toggleSidenav () {
            this.layoutHelpers.toggleCollapsed()
        },

        getLayoutNavbarBg () {
            return this.layoutNavbarBg
        },
        updateQuery () {
            this.query = this.value
            this.update()
        },
        onHit (item) {
            this.value = item.screen_name
            this.reset()
        },
        resetInput () {
            this.value = ''
            this.reset()
        }
    }
}
</script>

<style>
    #autocomplete {
  max-width: 400px;
  margin: 0 auto;
}

.autocomplete-result {
  border-top: 1px solid #eee;
  padding: 16px;
  background: transparent;
}

.group {
  padding: 16px;
  text-transform: uppercase;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.06);
}

.wiki-title {
  font-size: 20px;
  margin-bottom: 8px;
}

.wiki-snippet {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

</style>
